import ButtonCore from '../Button';
import PropTypes from 'prop-types';

const Button = ({ children, size = 'md', noIcon, icon, className, ...props }) => (
  <ButtonCore
    className={className}
    size={size}
    icon={icon !== undefined ? icon : noIcon ? false : undefined}
    {...props}
  > { children}
  </ButtonCore>
);

Button.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  noIcon(props, propName, componentName, ...rest) {
    if (props[propName] !== undefined)
      console.error(`"${propName}" property of "${componentName}" has been deprecated and will be removed after 2022-08-10.\nUse "icon" property with passed "false" value instead.`);

    return PropTypes.bool(props, propName, componentName, ...rest);
  },
  icon: PropTypes.node,
  className: PropTypes.string,
};

export default Button;
